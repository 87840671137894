import React, { Component } from "react"
import Slider from "react-slick"
import { SamplePrevArrow, SampleNextArrow } from "./ChevronArrows"
import ImageMeta from "../../components/ImageMeta"

const SimpleSlider = props => {
  const settings = {
    className: "desktop-slider-375x375",
    accessibility: true,
    adaptiveHeight: false,
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    focusOnSelect: false,
    draggable: false,
    rows: 1,
    speed: 500,
    nextArrow: <SampleNextArrow targetID={props.slideID} />,
    prevArrow: <SamplePrevArrow targetID={props.slideID} />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1125,
        settings: {
          slidesToShow: 3,
          infinite: true
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          infinite: true
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          infinite: true
        }
      }
    ]
  }

  const sliderStyle = {
    width: "auto",
    height: "auto",
    margin: "0px auto"
  }

  return (
    <div>
      <div id={props.slideID}>
        <Slider {...settings} style={{ ...sliderStyle }}>
          {props.images.map((img, i) => (
            <div className="image-wrap" key={i}>
              <ImageMeta cloudName="nuvolum" publicId={img} responsive />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default SimpleSlider
